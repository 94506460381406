import React from 'react';
import * as ReactDOM from 'react-dom/client';
import * as Sentry from '@sentry/react';

import './index.css';

import {ChatId, InboundDisplayMode, BrandingSettings} from './types';
import App from 'components/App/App';
import api, {senseApi, genericBotApi} from 'utils/bot-api';
import {getCustomParams, nonSenseParams} from 'utils/params';

import {embedReciteMe} from 'utils/reciteme';
import WidgetButton from './components/WidgetButton/WidgetButton';

Sentry.init({
  dsn:
    'https://0cd70e75df76f32876cbd0dbcbc4fcae@o31059.ingest.sentry.io/4506667495260160',
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: [
        'localhost',
        /^https:\/\/.*\/\.sensehqchat\.com\.*/,
        /^https:\/\/.*\/\.sensehq\.com\/api\/v1\/.*/,
      ],
    }),
    // marcos: not using this right now
    // new Sentry.Replay({
    //   maskAllText: false,
    //   blockAllMedia: false,
    // }),
  ],
  // Performance Monitoring
  tracesSampleRate: 0.1, //  Capture 10% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const initStandalone = (location: Location) => {
  const searchParams = new URLSearchParams(location.search);

  const flowId = searchParams.get('flowId');
  const isSms = searchParams.get('isSms');
  const isPreview = searchParams.get('isPreview') === 'true' || false;

  // NOTE (kyle): if a `flowId` is specified in the search params,
  // we ignore any pathnames.
  let chatId: ChatId;
  if (flowId) {
    // NOTE (kyle): this should only happen once.
    api.setBasePath('/api/nlu');
    chatId = {value: flowId, type: 'flow_id'};
  } else {
    const chatCode = location.pathname.replace(/^\//, '');
    chatId = {value: chatCode, type: 'chat_code'};
  }

  const customParams = nonSenseParams(searchParams);

  root.render(
    <App
      chatId={chatId}
      isSms={Boolean(isSms) && isSms !== 'false'}
      isPreview={isPreview}
      customParams={customParams}
    />,
  );
};

const initInboundBot = () => {
  if (window.parent === window) {
    root.render(
      <div>this inbound widget is supposed to be embedded by a script</div>,
    );

    return;
  }

  root.render(<div>waiting to load chatbot...</div>);

  // once we render this placeholder ui, we send the load message
  // back up to the parent window
  window.parent.postMessage(
    {
      type: 'load',
    },
    '*',
  );

  let setData = (any: any) => console.warn('waiting for inbound data');

  const Shell = () => {
    const [data, setAppData] = React.useState<null | {
      type: 'start-flow' | 'setDisplayMode';
      flow_id: string;
      customParams: {[key: string]: string};
      side: 'left' | 'right';
      is_mobile: boolean;
      mode: InboundDisplayMode;
      brand_settings: BrandingSettings;
      loadConversation: boolean;
    }>(null);

    console.log('chatbot shell rendered');
    setData = setAppData;

    const handleChangeDisplayMode = (displayMode: InboundDisplayMode) => {
      window.parent.postMessage(
        {
          type: 'changeDisplayMode',
          payload: displayMode,
        },
        '*',
      );
    };
    const handleToggle = () => {
      window.parent.postMessage({type: 'toggleDisplayMode'}, '*');
    };

    if (data == null) {
      return null;
    }

    return (
      <App
        chatId={{type: 'flow_id', value: data.flow_id}}
        isSms={false}
        handleLogoClick={handleToggle}
        flowBrandSettings={data.brand_settings}
        handleChangeDisplayMode={handleChangeDisplayMode}
        inboundDisplayMode={data.mode}
        isMobile={data.is_mobile}
        side={data.side}
        customParams={data.customParams}
        loadTranscript={data.loadConversation}
      />
    );
  };

  const renderApp = () => {
    root.render(<Shell />);
  };

  window.addEventListener('message', event => {
    if (event.source !== window.parent) {
      return;
    }

    const {data} = event;

    console.log('[sense chat] received chatbot data from host webpage', data);

    if (data.reciteMeApiKey != null) {
      console.log('[sense chat] initializing reciteme integration', data);
      embedReciteMe(data.reciteMeApiKey);
    }

    if (data.type === 'start-flow') {
      console.log('[sense chat] rendering app and starting chatbot flow', data);
      setData(data);
    } else if (data.type === 'setDisplayMode') {
      // @ts-ignore
      setData(prevData => ({...prevData, ...data}));
    }
  });

  renderApp();
};

const rootEl = document.getElementById('root');
if (!rootEl) {
  throw new Error('cannot render react without an app root');
}
const root = ReactDOM.createRoot(rootEl);

if (window.location.pathname === '/inbound-button') {
  const handleToggle = () => {
    window.parent.postMessage({type: 'toggleDisplayMode'}, '*');
  };

  const renderButton = (opened: boolean, flowBrandSettings: BrandingSettings) =>
    root.render(
      <WidgetButton
        opened={opened}
        onClick={handleToggle}
        flowBrandSettings={flowBrandSettings}
      />,
    );

  renderButton(false, {});

  window.addEventListener('message', event => {
    if (event.source !== window.parent) {
      return;
    }
    const {data} = event;
    if (data.type === 'setDisplayMode') {
      renderButton(data.mode !== 'min', data.brand_settings);
    }
  });
} else if (window.location.pathname === '/inbound') {
  initInboundBot();
} else if (window.location.pathname.startsWith('/s/')) {
  const suffix = window.location.pathname.replace(new RegExp('^/s'), '');
  const searchParams = new URL(window.location.href).searchParams;
  const s_cid = searchParams.get('s_cid') || '';
  const s_jid = searchParams.get('s_jid') || '';

  const params: {
    suffix: string;
    s_cid?: string | null;
  } = {
    suffix: suffix,
  };

  if (window.location.pathname.startsWith('/s/qn_over_sms')) {
    params.s_cid = s_cid;
  }

  senseApi
    .post(`/chatbot/flow/by-url-suffix`, params)
    .then(flowResource => {
      const {
        brand_settings,
        sourcing_config,
        agency_id,
        id,
        anchor_entity_type,
        show_top_faqs,
        top_faq_ids,
        status,
        use_case,
        flow_config,
        lat_config,
        bot_type,
      } = flowResource;
      const flowData = {
        id,
        anchor_entity_type,
        branding_settings: brand_settings,
        show_top_faqs,
        top_faq_ids,
        flow: {
          id: id,
          status,
          use_case,
          is_feedback_enabled: flow_config.feedback_enabled,
        },
        lat_config,
        bot_type,
      };
      const customParams = getCustomParams(
        sourcing_config?.application_bot_external_map ?? {},
      );

      if (params.s_cid) {
        genericBotApi
          .get(`/bot/conversations/${s_cid}/${s_jid}/application-status`)
          .then(({job_selected, valid_application, message}) => {
            if (valid_application) {
              root.render(
                <App
                  chatId={{
                    type: 'flow_id',
                    value: flowResource?.id ?? undefined,
                  }}
                  isSms={false}
                  customParams={{
                    'external/s_cid': s_cid,
                    'external/s_jid': s_jid,
                  }}
                  loadTranscript={true}
                  qnOverSms={true}
                  jobSelected={job_selected}
                  flowBrandSettings={brand_settings}
                  agencyId={agency_id}
                  flowData={flowData}
                />,
              );
            } else {
              root.render(
                <App
                  chatId={{type: 'flow_id', value: undefined}}
                  isSms={false}
                  error={message}
                />,
              );
            }
          });
      } else {
        root.render(
          <App
            chatId={{type: 'flow_id', value: flowResource?.id ?? undefined}}
            isSms={false}
            customParams={customParams}
          />,
        );
      }
    })
    .catch(err => {
      root.render(
        <App
          chatId={{type: 'flow_id', value: undefined}}
          isSms={false}
          error={err}
        />,
      );
    });
} else if (window.location.pathname.startsWith('/source/')) {
  const parts = window.location.pathname.split('/');
  if (parts.length === 3) {
    root.render(
      <App chatId={{type: 'flow_id', value: parts[2]}} isSms={false} />,
    );
  }
} else {
  initStandalone(window.location);
}
